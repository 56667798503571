import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import styled from "styled-components"
import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Divider,
  MenuItem,
  Fade,
} from "@mui/material"
import {
  MdMenu,
  MdAdd,
  MdReplay,
  MdEdit,
  MdDelete,
  MdSettingsBackupRestore,
  MdMoreHoriz,
  MdMoreVert,
} from "react-icons/md"
import { GiCardRandom, GiCardboardBox } from "react-icons/gi"
import { FaPlay } from "react-icons/fa"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Section from "../../components/section"
import ShiningEffect from "../../components/shining-effect"
import NumberCircle from "../../components/number-circle"
import { StyledPopover } from "../../components/styles"
import getSiteColor from "../../functions/get-site-color"
import hexToRgbaColor from "../../functions/hex-to-rgba-color"
import randomString from "../../functions/random-string"
import drawLots from "../../static/random-picker"
import { setCurrentPage } from "../../states/slices/main-slice"
import { playSound } from "../../states/slices/sound-slice"

const MenuBar = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
`

const MainRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 325px);
  min-height: 125px;

  @media (max-width: 767px) {
    flex-direction: column;
    height: 100%;
  }
`

const Col = styled.div`
  padding: 12px 16px;
  width: calc(50% - 32px);
  height: calc(100% - 24px);
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) =>
    theme === `light` ? `rgb(255, 255, 255)` : `rgb(45, 45, 45)`};
  border: 1px solid
    ${({ theme }) => (theme === `light` ? `#ced0d4` : `#3e4042`)};
  border-radius: 24px;
  transition: 0.3s;
  overflow: hidden;

  @media (max-width: 767px) {
    width: calc(100% - 32px - 2px);
    height: 100%;
    min-height: 250px;
    max-height: 350px;
  }
`

const ListItemsBlock = styled(Col)`
  margin-right: 0.5rem;

  @media (max-width: 767px) {
    margin-right: 0;
    margin-bottom: 1rem;
  }
`

const ResultBlock = styled(Col)`
  margin-left: 0.5rem;

  @media (max-width: 767px) {
    margin-left: 0;
    margin-bottom: 1rem;
  }
`

const Head = styled.span`
  position: relative;
  color: ${({ theme }) =>
    theme === `light` ? `rgb(0, 0, 0)` : `rgb(255, 255, 255)`};
  transition: 0.3s;
  line-height: 16px;

  span.group {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  span.title {
    display: inline-block;
    width: 100%;
  }

  span.count {
    display: inline-block;
    width: 100%;
    font-size: 0.75rem;
  }
`

const Body = styled.div`
  overflow: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
`

const Footer = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) =>
    theme === `light` ? `rgb(0, 0, 0)` : `rgb(255, 255, 255)`};
  transition: 0.3s;
`

const ListItemRow = styled.div`
  display: flex;
  flex-direction: column-reverse;
  overflow: hidden;
`

const ListItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;

  div.list__item {
    max-width: calc(100% - 40px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.25rem;
    border-radius: 8px;
    background-color: ${({ theme }) =>
      theme === `light` ? `rgb(240, 242, 245)` : `rgb(58, 59, 60)`};
    color: ${({ theme }) =>
      theme === `light` ? `rgb(0, 0, 0)` : `rgb(255, 255, 255)`};
    transition: 0.3s;
    cursor: pointer;
    user-select: none;

    span.name {
      padding: 0.75rem 1rem;
      width: 100%;
      height: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: ${({ theme }) =>
        theme === `light` ? `rgb(0, 0, 0)` : `rgb(255, 255, 255)`};
      transition: 0.3s;

      &:focus {
        outline: none;
      }
    }

    .MuiButtonBase-root.MuiIconButton-root {
      transition: 0.3s;
      padding: 10px;
      border-radius: 8px;

      .MuiTouchRipple-root {
        .MuiTouchRipple-ripple.MuiTouchRipple-rippleVisible {
          .MuiTouchRipple-child {
            border-radius: 8px;
          }
        }
      }
    }

    &:hover {
      transition: 0.1s;

      ${({ theme, siteColor }) => `
        background-color: ${
          theme === `light`
            ? hexToRgbaColor(getSiteColor(siteColor, theme), 0.2)
            : hexToRgbaColor(getSiteColor(siteColor, theme), 0.2)
        };

        span.name {
          color: ${getSiteColor(siteColor, theme)};
        }
      `}
    }
  }
`

const Notice = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  svg.info {
    font-size: 4rem;
    margin-bottom: 0.5rem;
    color: ${({ theme }) =>
      theme === `light` ? `rgba(0, 0, 0, 0.5)` : `rgba(255, 255, 255, 0.5)`};
    transition: 0.3s;
  }

  span.desc {
    color: ${({ theme }) =>
      theme === `light` ? `rgba(0, 0, 0, 0.75)` : `rgba(255, 255, 255, 0.75)`};
    transition: 0.3s;
  }

  button.suggest-btn {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    transition: 0.3s;
  }
`

const svgButtonStyles = {
  style: {
    fontSize: `1.5rem`,
  },
}

const DrawLots = () => {
  const { lang, theme, siteColor } = useSelector(
    ({ mainReducer }) => mainReducer
  )
  const dispatch = useDispatch()
  const [menuAnchorEl, setMenuAnchorEl] = useState(null)
  const [moreOptAnchorEl, setMoreOptAnchorEl] = useState(null)
  const [moreOptPickedUpAnchorEl, setMoreOptPickedUpAnchorEl] = useState(null)
  const [inBoxOptUpAnchorEl, setInBoxOptAnchorEl] = useState(null)
  const [listItems, setListItems] = useState([])
  const [addItemModalOpen, setAddItemModalOpen] = useState(false)
  const [editItemModalOpen, setEditItemModalOpen] = useState(false)
  const [returnItemsModalOpen, setReturnItemsModalOpen] = useState(false)
  const [removeAllItemsModalOpen, setRemoveAllItemsModalOpen] = useState(false)
  const [restoreDefaultModalOpen, setRestoreDefaultModalOpen] = useState(false)
  const [optionForRemoveAll, setOptionForRemoveAll] = useState(false)
  const [inputItem, setInputItem] = useState(``)
  const [currentItem, setCurrentItem] = useState({})
  const [autoIncrement, setAutoIncrement] = useState(0)

  useEffect(() => {
    dispatch(
      setCurrentPage({
        page: `random-picker`,
        group: `app`,
      })
    )
  }, [dispatch])

  useEffect(() => {
    if (listItems.length === 0) {
      setAutoIncrement(0)
    }
  }, [listItems])

  const generateId = () => {
    return `${new Date().valueOf()}${randomString(8)}`
  }

  const generateTimeStamp = () => {
    return new Date().valueOf()
  }

  const backToInitialState = () => {
    setListItems([])
  }

  const openItemOption = (getItem, getEvent) => {
    setCurrentItem(getItem)
    setInBoxOptAnchorEl(getEvent.currentTarget)
  }

  const closeItemOption = () => {
    setInBoxOptAnchorEl(null)
  }

  const addItem = () => {
    dispatch(playSound(`confirm`))
    setListItems([
      ...listItems,
      {
        id: generateId(),
        name: inputItem,
        isPicked: false,
        order: autoIncrement + 1,
        lastUpdate: generateTimeStamp(),
      },
    ])
    setAutoIncrement(autoIncrement + 1)
    setInputItem(``)
    setAddItemModalOpen(false)
  }

  const handleClickEditListItem = getItemId => {
    const getItem = listItems.find(item => item.id === getItemId)

    setInputItem(getItem.name)
    setCurrentItem(getItem)
    setEditItemModalOpen(true)
  }

  const editItem = () => {
    dispatch(playSound(`confirm`))
    const getIndexFromItemId = listItems.findIndex(
      item => item.id === currentItem.id
    )

    let newListItems = [...listItems]
    newListItems[getIndexFromItemId] = {
      ...newListItems[getIndexFromItemId],
      name: inputItem,
    }

    setListItems(newListItems)
    setInputItem(``)
    setEditItemModalOpen(false)
  }

  const moveItemBack = () => {
    const getItemId = currentItem.id
    const getIndexFromItemId = listItems.findIndex(
      item => item.id === getItemId
    )

    let newListItems = [...listItems]
    newListItems[getIndexFromItemId] = {
      ...newListItems[getIndexFromItemId],
      isPicked: false,
      lastUpdate: generateTimeStamp(),
    }

    setListItems(newListItems)
  }

  const moveAllItemsBack = () => {
    let newListItems = [...listItems]

    for (let i in newListItems) {
      newListItems[i] = {
        ...newListItems[i],
        isPicked: false,
      }
    }

    setListItems(newListItems)
  }

  const deleteItem = () => {
    const getItemId = currentItem.id
    setListItems(listItems.filter(item => item.id !== getItemId))
  }

  const deleteAllItem = getOption => {
    if (getOption === `picked-up-items`) {
      setListItems(listItems.filter(item => item.isPicked !== true)) // discard all items in picked up list items box
    } else {
      setListItems(listItems.filter(item => item.isPicked === true)) // discard all items in list items box
    }

    setRemoveAllItemsModalOpen(false)
  }

  const drawALot = () => {
    const filteredListItems = listItems.filter(item => item.isPicked !== true)
    const randomizedIndex = Math.floor(Math.random() * filteredListItems.length)
    const getItemFromRandomizedIndex = filteredListItems.find(
      (_, index) => index === randomizedIndex
    )
    const getIndexFromRealListItems = listItems.findIndex(
      element => element.id === getItemFromRandomizedIndex.id
    )

    let newListItems = [...listItems]
    newListItems[getIndexFromRealListItems] = {
      ...newListItems[getIndexFromRealListItems],
      isPicked: true,
      lastUpdate: generateTimeStamp(),
    }

    setListItems(newListItems)
  }

  const renderListItemsFilter = () => {
    const modifiedListItems = listItems
      .filter(item => item.isPicked !== true)
      .sort((a, b) => {
        return a.lastUpdate - b.lastUpdate
      })

    return modifiedListItems
  }

  const renderPickedUpListItemsFilter = () => {
    const modifiedListItems = listItems
      .filter(item => item.isPicked === true)
      .sort((a, b) => {
        return a.lastUpdate - b.lastUpdate
      })

    return modifiedListItems
  }

  return (
    <Layout>
      <Seo title={drawLots.pageName[lang]} />
      <Section title={drawLots.title[lang]} icon={<GiCardRandom />}>
        <MenuBar theme={theme}>
          <Button
            style={{
              position: `relative`,
              overflow: `hidden`,
              transition: `0.3s`,
            }}
            color="primary"
            variant="contained"
            aria-label="draw-a-lot"
            disabled={renderListItemsFilter().length === 0}
            onClick={() => {
              drawALot()
              dispatch(playSound(`confirm`))
            }}
          >
            {renderListItemsFilter().length > 0 && (
              <ShiningEffect style={{ borderRadius: 50 }} width={25} />
            )}
            <FaPlay
              {...svgButtonStyles}
              style={{ ...svgButtonStyles.style, marginRight: 5 }}
            />
            {drawLots.button.randomPick[lang]}
          </Button>
          <Button
            style={{
              transition: `color 0s`,
            }}
            color="inherit"
            aria-label="open-menu"
            aria-controls="open-menu"
            aria-haspopup="true"
            onClick={event => {
              setMenuAnchorEl(event.currentTarget)
              dispatch(playSound(`confirm`))
            }}
          >
            <MdMenu {...svgButtonStyles} />
            {drawLots.label.menu[lang]}
          </Button>
          <StyledPopover
            id="open-menu"
            anchorEl={menuAnchorEl}
            keepMounted
            open={Boolean(menuAnchorEl)}
            onClose={() => {
              setMenuAnchorEl(null)
              dispatch(playSound(`cancel`))
            }}
            TransitionComponent={Fade}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <div className="popover__title">{drawLots.label.menu[lang]}</div>
            <Divider />
            <div>
              <MenuItem
                onClick={() => {
                  setRestoreDefaultModalOpen(true)
                  setMenuAnchorEl(null)
                  dispatch(playSound(`confirm`))
                }}
                disabled={listItems.length === 0}
              >
                <MdSettingsBackupRestore {...svgButtonStyles} />
                {drawLots.button.restart[lang]}
              </MenuItem>
            </div>
          </StyledPopover>
        </MenuBar>
        <MainRow>
          <ListItemsBlock theme={theme}>
            <Head theme={theme}>
              <span className="title">{drawLots.head.listItems[lang]}</span>
              <span className="count">
                {renderListItemsFilter().length}{" "}
                {lang === `en`
                  ? renderListItemsFilter().length === 1
                    ? drawLots.head.count.en.eqOne
                    : drawLots.head.count.en.neqOne
                  : drawLots.head.count[lang]}
              </span>
              <IconButton
                style={{
                  position: `absolute`,
                  top: 0,
                  right: 0,
                }}
                color="inherit"
                size="small"
                aria-label="more"
                aria-controls="list-items-option"
                aria-haspopup="true"
                onClick={event => {
                  setMoreOptAnchorEl(event.currentTarget)
                  dispatch(playSound(`confirm`))
                }}
              >
                <MdMoreHoriz {...svgButtonStyles} />
              </IconButton>
              <StyledPopover
                id="list-items-option"
                anchorEl={moreOptAnchorEl}
                keepMounted
                open={Boolean(moreOptAnchorEl)}
                onClose={() => {
                  setMoreOptAnchorEl(null)
                  dispatch(playSound(`cancel`))
                }}
                TransitionComponent={Fade}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <div className="popover__title">
                  {drawLots.label.option[lang]}
                </div>
                <Divider />
                <div>
                  <MenuItem
                    onClick={() => {
                      setAddItemModalOpen(true)
                      setMoreOptAnchorEl(null)
                      dispatch(playSound(`confirm`))
                    }}
                  >
                    <MdAdd {...svgButtonStyles} />
                    {drawLots.button.add[lang]}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setOptionForRemoveAll(`items`)
                      setRemoveAllItemsModalOpen(true)
                      setMoreOptAnchorEl(null)
                      dispatch(playSound(`confirm`))
                    }}
                    disabled={renderListItemsFilter().length === 0}
                  >
                    <MdDelete {...svgButtonStyles} />
                    {drawLots.button.removeAll[lang]}
                  </MenuItem>
                </div>
              </StyledPopover>
            </Head>
            <Body>
              {renderListItemsFilter().length > 0 ? (
                <ListItemRow>
                  {renderListItemsFilter().map((item, itemIndex) => {
                    return (
                      <ListItem
                        key={itemIndex}
                        className="animate__animated animate__fadeInDown"
                        theme={theme}
                        siteColor={siteColor}
                      >
                        <NumberCircle number={item.order} />
                        <div className="list__item">
                          <span
                            className="name"
                            role="button"
                            onClick={() => {
                              handleClickEditListItem(item.id)
                              dispatch(playSound(`confirm`))
                            }}
                            onKeyPress={() => {}}
                            tabIndex={-1}
                          >
                            {item.name}
                          </span>
                          <IconButton
                            color="primary"
                            aria-label="in-box-option"
                            aria-controls="open-in-box-option"
                            aria-haspopup="true"
                            onClick={event => {
                              openItemOption(item, event)
                              dispatch(playSound(`confirm`))
                            }}
                          >
                            <MdMoreVert />
                          </IconButton>
                        </div>
                      </ListItem>
                    )
                  })}
                </ListItemRow>
              ) : (
                <Notice
                  className="animate__animated animate__fadeIn"
                  theme={theme}
                >
                  <GiCardboardBox className="info" />
                  <span className="desc">{drawLots.notice.listItem[lang]}</span>
                </Notice>
              )}
            </Body>
            <Footer theme={theme}>
              <Button
                fullWidth
                style={{
                  transition: `color 0s`,
                }}
                color="inherit"
                aria-label="open-add-item-modal"
                onClick={() => {
                  setAddItemModalOpen(true)
                  dispatch(playSound(`confirm`))
                }}
              >
                <MdAdd {...svgButtonStyles} />
                {drawLots.button.add[lang]}
              </Button>
            </Footer>
          </ListItemsBlock>
          <ResultBlock theme={theme}>
            <Head theme={theme}>
              <span className="title">
                {drawLots.head.pickedUpListItems[lang]}
              </span>
              <span className="count">
                {renderPickedUpListItemsFilter().length}{" "}
                {lang === `en`
                  ? renderPickedUpListItemsFilter().length === 1
                    ? drawLots.head.count.en.eqOne
                    : drawLots.head.count.en.neqOne
                  : drawLots.head.count[lang]}
              </span>
              <IconButton
                style={{
                  position: `absolute`,
                  top: 0,
                  right: 0,
                }}
                color="inherit"
                size="small"
                aria-label="more"
                aria-controls="picked-up-list-items-option"
                aria-haspopup="true"
                onClick={event => {
                  setMoreOptPickedUpAnchorEl(event.currentTarget)
                  dispatch(playSound(`confirm`))
                }}
              >
                <MdMoreHoriz {...svgButtonStyles} />
              </IconButton>
              <StyledPopover
                id="picked-up-list-items-option"
                anchorEl={moreOptPickedUpAnchorEl}
                keepMounted
                open={Boolean(moreOptPickedUpAnchorEl)}
                onClose={() => {
                  setMoreOptPickedUpAnchorEl(null)
                  dispatch(playSound(`cancel`))
                }}
                TransitionComponent={Fade}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <div className="popover__title">
                  {drawLots.label.option[lang]}
                </div>
                <Divider />
                <div>
                  <MenuItem
                    onClick={() => {
                      setReturnItemsModalOpen(true)
                      setMoreOptPickedUpAnchorEl(null)
                      dispatch(playSound(`confirm`))
                    }}
                    disabled={renderPickedUpListItemsFilter().length === 0}
                  >
                    <MdReplay {...svgButtonStyles} />
                    {drawLots.button.returnAll[lang]}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setOptionForRemoveAll(`picked-up-items`)
                      setRemoveAllItemsModalOpen(true)
                      setMoreOptPickedUpAnchorEl(null)
                      dispatch(playSound(`confirm`))
                    }}
                    disabled={renderPickedUpListItemsFilter().length === 0}
                  >
                    <MdDelete {...svgButtonStyles} />
                    {drawLots.button.removeAll[lang]}
                  </MenuItem>
                </div>
              </StyledPopover>
            </Head>
            <Body>
              {renderPickedUpListItemsFilter().length > 0 ? (
                <ListItemRow>
                  {renderPickedUpListItemsFilter().map((item, itemIndex) => {
                    return (
                      <ListItem
                        key={itemIndex}
                        className="animate__animated animate__fadeInDown"
                        theme={theme}
                        siteColor={siteColor}
                      >
                        <NumberCircle number={item.order} />
                        <div className="list__item">
                          <span
                            className="name"
                            role="button"
                            onClick={() => {
                              handleClickEditListItem(item.id)
                              dispatch(playSound(`confirm`))
                            }}
                            onKeyPress={() => {}}
                            tabIndex={-1}
                          >
                            {item.name}
                          </span>
                          <IconButton
                            color="primary"
                            aria-label="in-box-option"
                            aria-controls="open-in-box-option"
                            aria-haspopup="true"
                            onClick={event => {
                              openItemOption(item, event)
                              dispatch(playSound(`confirm`))
                            }}
                          >
                            <MdMoreVert />
                          </IconButton>
                        </div>
                      </ListItem>
                    )
                  })}
                </ListItemRow>
              ) : (
                <Notice
                  className="animate__animated animate__fadeIn"
                  theme={theme}
                >
                  <GiCardboardBox className="info" />
                  <span className="desc">
                    {drawLots.notice.pickedUpListItems[lang]}
                  </span>
                </Notice>
              )}
            </Body>
          </ResultBlock>
        </MainRow>
      </Section>

      {/* In box popover */}
      <StyledPopover
        id="in-box-option"
        anchorEl={inBoxOptUpAnchorEl}
        keepMounted
        open={Boolean(inBoxOptUpAnchorEl)}
        onClose={() => {
          closeItemOption()
          dispatch(playSound(`cancel`))
        }}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <div>
          {currentItem.isPicked === true && (
            <>
              <MenuItem
                onClick={() => {
                  moveItemBack()
                  closeItemOption()
                  dispatch(playSound(`confirm`))
                }}
              >
                <MdReplay {...svgButtonStyles} />
                {drawLots.button.returnOne[lang]}
              </MenuItem>
              <Divider style={{ margin: `0.5rem 0` }} />
            </>
          )}
          <MenuItem
            onClick={() => {
              handleClickEditListItem(currentItem.id)
              closeItemOption()
              dispatch(playSound(`confirm`))
            }}
          >
            <MdEdit {...svgButtonStyles} />
            {drawLots.button.edit[lang]}
          </MenuItem>
          <MenuItem
            onClick={() => {
              deleteItem()
              closeItemOption()
              dispatch(playSound(`confirm`))
            }}
          >
            <MdDelete {...svgButtonStyles} />
            {drawLots.button.removeOne[lang]}
          </MenuItem>
        </div>
      </StyledPopover>

      {/* Add item modal */}
      <Dialog fullWidth maxWidth="xs" open={addItemModalOpen}>
        <DialogTitle>{drawLots.addItemModal.title[lang]}</DialogTitle>
        <DialogContent dividers>
          <div>
            <TextField
              style={{ width: `100%` }}
              size="small"
              label={drawLots.addItemModal.textBoxDescription[lang]}
              value={inputItem}
              inputRef={node => {
                if (node !== null) {
                  node.focus()
                }
              }}
              onChange={e => {
                setInputItem(e.target.value)
              }}
              onKeyPress={e => {
                if (inputItem !== ``) {
                  if (e.key === "Enter") {
                    addItem()
                  }
                }
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color="inherit"
            onClick={() => {
              setAddItemModalOpen(false)
              setInputItem(``)
              dispatch(playSound(`cancel`))
            }}
            aria-label="close-add-item-modal"
          >
            {drawLots.addItemModal.button.cancel[lang]}
          </Button>
          <Button
            style={{
              transition: `0.3s`,
            }}
            color="primary"
            variant="contained"
            onClick={() => {
              addItem()
            }}
            disabled={inputItem === ``}
            aria-label="confirm-add-item-modal"
          >
            {drawLots.addItemModal.button.confirm[lang]}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit item modal */}
      <Dialog fullWidth maxWidth="xs" open={editItemModalOpen}>
        <DialogTitle>{drawLots.editItemModal.title[lang]}</DialogTitle>
        <DialogContent dividers>
          <div>
            <TextField
              style={{ width: `100%` }}
              size="small"
              label={drawLots.editItemModal.textBoxDescription[lang]}
              value={inputItem}
              inputRef={node => {
                if (node !== null) {
                  node.focus()
                }
              }}
              onChange={e => {
                setInputItem(e.target.value)
              }}
              onKeyPress={e => {
                if (inputItem !== ``) {
                  if (e.key === "Enter") {
                    editItem()
                  }
                }
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color="inherit"
            onClick={() => {
              setEditItemModalOpen(false)
              setInputItem(``)
              dispatch(playSound(`cancel`))
            }}
            aria-label="close-edit-item-modal"
          >
            {drawLots.editItemModal.button.cancel[lang]}
          </Button>
          <Button
            style={{
              transition: `0.3s`,
            }}
            color="primary"
            variant="contained"
            onClick={() => {
              editItem()
            }}
            disabled={inputItem === ``}
            aria-label="confirm-edit-item-modal"
          >
            {drawLots.editItemModal.button.confirm[lang]}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Remove all items modal */}
      <Dialog fullWidth maxWidth="xs" open={removeAllItemsModalOpen}>
        <DialogTitle>{drawLots.removeAllItemsModal.title[lang]}</DialogTitle>
        <DialogContent dividers>
          {drawLots.removeAllItemsModal.description[lang]}
        </DialogContent>
        <DialogActions>
          <Button
            color="inherit"
            onClick={() => {
              setRemoveAllItemsModalOpen(false)
              dispatch(playSound(`cancel`))
            }}
            aria-label="close-remove-all-items-modal"
          >
            {drawLots.removeAllItemsModal.button.cancel[lang]}
          </Button>
          <Button
            style={{
              transition: `0.3s`,
            }}
            color="primary"
            variant="contained"
            onClick={() => {
              deleteAllItem(optionForRemoveAll)
              dispatch(playSound(`confirm`))
            }}
            aria-label="confirm-remove-all-items-modal"
          >
            {drawLots.removeAllItemsModal.button.confirm[lang]}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Discard all items modal */}
      <Dialog fullWidth maxWidth="xs" open={restoreDefaultModalOpen}>
        <DialogTitle>{drawLots.discardAllItemsModal.title[lang]}</DialogTitle>
        <DialogContent dividers>
          {drawLots.discardAllItemsModal.description[lang]}
        </DialogContent>
        <DialogActions>
          <Button
            color="inherit"
            onClick={() => {
              setRestoreDefaultModalOpen(false)
              dispatch(playSound(`cancel`))
            }}
            aria-label="close-discard-all-items-modal"
          >
            {drawLots.discardAllItemsModal.button.cancel[lang]}
          </Button>
          <Button
            style={{
              transition: `0.3s`,
            }}
            color="primary"
            variant="contained"
            onClick={() => {
              setRestoreDefaultModalOpen(false)
              backToInitialState()
              dispatch(playSound(`confirm`))
            }}
            aria-label="confirm-discard-all-items-modal"
          >
            {drawLots.discardAllItemsModal.button.confirm[lang]}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Return picked up items modal */}
      <Dialog fullWidth maxWidth="xs" open={returnItemsModalOpen}>
        <DialogTitle>
          {drawLots.returnPickedUpItemsModal.title[lang]}
        </DialogTitle>
        <DialogContent dividers>
          {drawLots.returnPickedUpItemsModal.description[lang]}
        </DialogContent>
        <DialogActions>
          <Button
            color="inherit"
            onClick={() => {
              setReturnItemsModalOpen(false)
              dispatch(playSound(`cancel`))
            }}
            aria-label="close-remove-all-items-modal"
          >
            {drawLots.returnPickedUpItemsModal.button.cancel[lang]}
          </Button>
          <Button
            style={{
              transition: `0.3s`,
            }}
            color="primary"
            variant="contained"
            onClick={() => {
              setReturnItemsModalOpen(false)
              moveAllItemsBack()
              dispatch(playSound(`confirm`))
            }}
            aria-label="confirm-remove-all-items-modal"
          >
            {drawLots.returnPickedUpItemsModal.button.confirm[lang]}
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  )
}

export default DrawLots
