import React from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import styled from "styled-components"

const Number = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 2rem;
  min-height: 2rem;
  max-width: 2rem;
  max-height: 2rem;
  font-weight: 600;
  border-radius: 100%;
  margin-right: 0.5rem;
  background-color: ${({ theme }) =>
    theme === `light` ? `rgba(0, 0, 0, 0.75)` : `rgba(255, 255, 255, 0.75)`};
  color: ${({ theme }) =>
    theme === `light` ? `rgb(255, 255, 255)` : `rgb(0, 0, 0)`};
  transition: 0.3s;
  overflow: hidden;
`

const NumberCircle = ({ number }) => {
  const { theme } = useSelector(({ mainReducer }) => mainReducer)

  return <Number theme={theme}>{number}</Number>
}

NumberCircle.propTypes = {
  number: PropTypes.number.isRequired,
}

export default NumberCircle
